.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    width: 100%;
    transform: translate3d(0, 0, 0);
}

.NavigationContainer {
    transform: translate3d(0, 0, 0);
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
}

.TitleAndLink {
    width: 100%;
}

.userName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: absolute;
    font-size: 24px;
    border-radius: 0 100px 100px 0;
}

.StudentName {
    background-color: #91B508;
    color: white;
    padding: 15px;
    padding-right: 25px;
    width: auto;
    position: absolute;
    font-size: 35px;
    border-radius: 0 100px 100px 0;
}

.LeftDiv {
    display: flex;
    align-items: center;
    height: 100%;
    width: 350px;
}

.RightDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 350px;
    padding-right: 15px;
}

.LogOutBt {
    color: #464545;
    background-color: white;
    width: 100%;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 600;
    margin-right: 15px;
}

@media (min-width:1201px) {
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 24px;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
    }
    .NavBar {
        height: 125px;
    }
    .CenterDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .CenterDivAdmin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .CenterDivAdmin>div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.RomanNumber {
    font-family: 'Roboto Slab', serif;
    letter-spacing: .1em;
    font-size: 16px;
}

@media (max-width:1200px) {
    .NavBar {
        height: 100px;
    }
    .CenterDiv {
        display: none;
    }
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .StudentTitle {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 18px;
    }
    .StudentGoBack {
        text-align: center;
        color: white;
        width: 100%;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
    }
    .LogOutBt {
        display: none;
    }
}

@media (max-width:600px) {
    .CenterDivStudent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 700px;
        overflow: hidden;
    }
    .StudentTitle {
     display: none;
    }

    .userName, .StudentName {
        font-size: 20px;
    }
}