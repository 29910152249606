.Link {
    margin-top: 40px;
}

.Link button {
    font-size: 20px;
    background-color: #005898;
    border: none;
    color: white;
    padding: 20px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}

.Link button:hover {
    background-color: #003050;
}



.ImageGalleryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProgramLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 10px;
}

.ProgramLogo img {
    width: 200px;
    height: 100%
}

.ProgramLogoOnSingleModel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.ProgramLogoOnSingleModel img {
    width: 200px;
    height: 100%
}

.PageConatiner {
    display: flex;
    justify-content: center;
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    z-index: -100;
    width: 100%;
    height: 100%;
}

.BackgroungImg {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: -1000;
}

.BackgroungImg img {
    width: 100%;
    height: 100%;
}

.MobileBackgroungImg {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1000;
}

.MobileBackgroungImg img {
    width: 100%;
    align-self: flex-start;
}

.StudentListAddUser {
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    font-size: 20px;
    color: #A3A3A3;
}

.StudentListAddUser img {
    width: 70px;
}

@media (min-width:1201px) {
    .Title {
        font-size: 30px;
        color: #003050;
        text-align: center;
        margin-top: 145px;
        margin-bottom: 10px;
    }
    .TitleFranchiseOwner {
        font-size: 30px;
        color: #003050;
        text-align: left;
        margin-top: 150px;
        margin-bottom: 10px;
    }
    .PageConatiner {
        padding-top: 125px;
    }
    .GoBack {
        text-align: center;
        margin-top: -5px;
        margin-bottom: 10px;
        color: #003050;
        cursor: pointer;
    }

    .GoBackLessonPlan {
        text-align: center;
        margin-top: 20px;
        margin-bottom: -10px;
        color: #003050;
        cursor: pointer;
    }

    .GoBackTermOfUse{
        text-align: center;
        margin-top: 0px;
       margin-bottom: 10px;
        color: #003050;
        cursor: pointer;
    }

}

@media (max-width:1200px) {
    .GoBack {
        text-align: center;
        margin-top: 3px;
        margin-bottom: 10px;
        color: #003050;
        cursor: pointer;
    }
    .GoBackLessonPlan {
        text-align: center;
        margin-top: 20px;
        margin-bottom: -10px;
        color: #003050;
        cursor: pointer;
    }

    .GoBackTermOfUse {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #003050;
        cursor: pointer;
    }
    .Title {
        font-size: 30px;
        color: #003050;
        text-align: center;
        margin-top: 110px;
        margin-bottom: 0px;
    }
    .TitleFranchiseOwner {
        font-size: 30px;
        color: #003050;
        text-align: left;
        margin-top: 120px;
        margin-bottom: 0px;
    }
    .PageConatiner {
        margin-top: 100px;
    }
    .ProgramLogo {
        margin-top: 120px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .Container {
        width: 725px;
    }
}

@media (min-width:1300px) {
    .Container {
        width: 1250px;
    }
}

.TableComment {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 40px;
}

.LoginSmallText {
    text-align: center;
    color: #464545;
}

@media (min-width:801px) {
    .LoginSmallText {
        font-size: 14px;
    }
    .FormContainer {
        margin-top: 10%;
        width: 550px;
        height: 100%;
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 35px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .FormContainer input {
        width: 350px;
    }
    .ForgotPassword {
        width: 350px;
        padding-left: 25px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .FormContainer button {
        width: 150px;
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        justify-content: center;
    }
    .YeLogo {
        height: 80%;
        width: auto;
        padding-right: 20px;
    }
    .OnlineLogo {
        height: 80%;
        width: auto;
        padding-left: 20px;
        position: relative;
        top: -7px;
    }
}

@media (max-width:800px) {
    .ForgotPassword {
        width: 100%;
        padding-left: 12px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .LoginSmallText {
        font-size: 12px;
    }
    .FormContainer {
        width: 65%;
        height: 100%;
        position: absolute;
        margin-top: 30%;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5%;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .LogosContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .YeLogo {
        height: 50%;
        width: 50%;
    }
    .OnlineLogo {
        height: 35%;
        width: 35%;
    }
}

@media (max-width:640px) {
    .Container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

