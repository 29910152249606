.FlexContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fasortIcon{
    position: relative;
    top:2px;
    cursor: pointer;
    color:#ffffff70;
    font-size: 14px;
  }
  
  .fasortIconActive{
color: white;
  }